import React from 'react';

const ArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14"
    fill="none"
    viewBox="0 0 20 14"
    {...props}
  >
    <path
      fill="#323232"
      d="M13 0l-1.41 1.41L16.17 6H0v2h16.17l-4.59 4.59L13 14l7-7-7-7z"
    ></path>
  </svg>
);

export default ArrowRightIcon;
